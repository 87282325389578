import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  stats: {},
  barchart: []
}

const dashboardReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_STATS:
      return {
        ...state,
        stats: action.payload
      }
    case ActionTypes.SET_BARCHART:
      return {
        ...state,
        barchart: action.payload
      }

    default:
      return state
  }
}

export default dashboardReducer