import React from 'react'
import moment from 'moment'

// Import Components
import { Form, Row, Col, DatePicker, Button } from 'antd'
import { getBarchartData } from '../../store/actions/dashboardActions'
import { connect } from 'react-redux'

class StyledDateRange extends React.PureComponent {
  state = {
    isLoading: false,
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTill: moment().format('YYYY-MM-DD')
  }

  // On Values Change
  _onValuesChange = (changedValues, allValues) => {
    if(allValues.dateRange) {
      this.setState({
        dateFrom: allValues.dateRange.length > 0 && allValues.dateRange[0] ?
          allValues.dateRange[0].format('YYYY-MM-DD') : null,
        dateTill: allValues.dateRange.length > 1 && allValues.dateRange[1] ?
          allValues.dateRange[1].format('YYYY-MM-DD') : null
      })

    } else {
      // Clear Chart
      this.setState({
        dateFrom: null,
        dateTill: null
      })
    }
  }

  // On Form Submit
  _onFinish = () => {
    // Callback
    const { dispatch } = this.props
    dispatch( getBarchartData() )
  }

  // On Form Submit Failed
  _onFinishFailed = errorInfo => {
    console.error('Submit Failed:', errorInfo)
  }

  // Validate Date
  _validateRange = date => {
    const today = moment().format('YYYY-MM-DD')
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    return date.format('YYYY-MM-DD') > today || date.format('YYYY-MM-DD') < startOfMonth
  }

  render() {
    const { isLoading, dateFrom, dateTill } = this.state

    return (
      <div>
        <Form
          layout='inline'
          autoComplete='off'
          initialValues={{
            dateRange: [
              dateFrom ? moment(dateFrom) : null,
              dateTill ? moment(dateTill) : null
            ]
          }}
          onValuesChange={ this._onValuesChange }
          onFinish={ this._onFinish }
          onFinishFailed={ this._onFinishFailed }
        >
          <Row justify='space-between' gutter={[ 8, 8 ]}>
            <Col xs={ 24 } lg={ 16 }>
              <Form.Item name='dateRange'>
                <DatePicker.RangePicker
                  disabled={[ false, false ]}
                  disabledDate={ this._validateRange }
                  ranges={{
                    'Today': [ moment(), moment() ],
                    'A Week': [ moment().subtract(6, 'day'), moment() ],
                    '30 Days': [ moment().subtract(29, 'day'), moment() ]
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={ 24 } lg={ 8 }>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={ isLoading }
                >
                  { 'Get Data' }
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(StyledDateRange)