import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Card, Row, Col } from 'antd'
import StyledDateRange from '../common/StyledDateRange'
import BarChart from '../common/BarChart'
import { getBarchartData } from '../../store/actions/dashboardActions'
import { connect } from 'react-redux'

class TargetChart extends React.PureComponent {
  componentDidMount(){
    const { dispatch } = this.props
    dispatch( getBarchartData() )

  }
  // Transform Stats Comparison Data
  _transformStatsComparisonData = () => {
    const { barchart } = this.props;
    console.log("barchart ", barchart);
    // Transform Stats Comparison Data Into Chart Prop Format
    const transformedStatsComparisonData = {
      // labels: [ 'Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5', 'Zone 6', 'Zone 7', 'Zone 8', 'Zone 9', 'Zone 10' ],
      labels: [ ],

      // datasets: [
      //   {
      //     label: 'Target Achieved (%)',
      //     data: [ 13, 50, 100, 30, 10, 20, 40, 60, 80, 90 ],
      //     order: 1
      //   },
      //   {
      //     label: 'Target Achieved (%)',
      //     data: [ 13, 50, 100, 30, 10, 20, 40, 60, 80, 90 ],
      //     type: 'line',
      //     order: 0
      //   }
      // ]
      datasets: [
        {
          label: 'Target Achieved',
          data: [],
          order: 1
        },
      ]
    }

    barchart.map((i) => {
      transformedStatsComparisonData?.labels.push(i?.zone);
      transformedStatsComparisonData?.datasets[0]?.data.push(i?.achive);
    })

    console.log("transformedStatsComparisonData", transformedStatsComparisonData);

    return transformedStatsComparisonData
  }
  
  render() {
    const _transformedStatsComparisonData = this._transformStatsComparisonData()

    return (
      <Card
        hoverable={ false }
        style={{ width: '100%' }}
      >
        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <StyledDateRange />
          </Col>
        </Row>

        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <Card hoverable={ false }>
              <BarChart
                data={ _transformedStatsComparisonData }
                style={{ minHeight: '120px' }}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    )
  }
}

// Prop Types
TargetChart.propTypes = {
  dispatch: PropTypes.func
}

TargetChart.defaultProps = {
  dispatch: () => null
}

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated ?? false,
  barchart: state?.dashboard?.barchart ?? []
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TargetChart)
