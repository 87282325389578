import axios from 'axios';
import { ZONE_AREAS } from '../App.config';

// DUMMY DATA
export const dummyData = [
  {
    total_holdings: 24832,
    total_tl: 57273,
    unpaid_holdings: 10602,
    unpaid_tl: 40093,
    holdings_revenue: 6,
    tl_revenue: 10,
    target_achieved: 18,
  },
  {
    total_holdings: 10608,
    total_tl: 31299,
    unpaid_holdings: 1584,
    unpaid_tl: 43564,
    holdings_revenue: 7,
    tl_revenue: 4,
    target_achieved: 92,
  },
  {
    total_holdings: 17509,
    total_tl: 30899,
    unpaid_holdings: 18463,
    unpaid_tl: 49656,
    holdings_revenue: 2,
    tl_revenue: 19,
    target_achieved: 75,
  },
  {
    total_holdings: 22149,
    total_tl: 22036,
    unpaid_holdings: 8781,
    unpaid_tl: 13234,
    holdings_revenue: 2,
    tl_revenue: 7,
    target_achieved: 10,
  },
  {
    total_holdings: 27447,
    total_tl: 29884,
    unpaid_holdings: 4230,
    unpaid_tl: 48507,
    holdings_revenue: 8,
    tl_revenue: 20,
    target_achieved: 95,
  },
  {
    total_holdings: 12670,
    total_tl: 56428,
    unpaid_holdings: 1881,
    unpaid_tl: 45102,
    holdings_revenue: 6,
    tl_revenue: 5,
    target_achieved: 97,
  },
  {
    total_holdings: 17823,
    total_tl: 42171,
    unpaid_holdings: 18254,
    unpaid_tl: 17389,
    holdings_revenue: 8,
    tl_revenue: 10,
    target_achieved: 79,
  },
  {
    total_holdings: 18322,
    total_tl: 20419,
    unpaid_holdings: 12619,
    unpaid_tl: 5229,
    holdings_revenue: 8,
    tl_revenue: 3,
    target_achieved: 71,
  },
  {
    total_holdings: 21632,
    total_tl: 33390,
    unpaid_holdings: 17973,
    unpaid_tl: 6160,
    holdings_revenue: 8,
    tl_revenue: 14,
    target_achieved: 13,
  },
  {
    total_holdings: 16293,
    total_tl: 38557,
    unpaid_holdings: 2067,
    unpaid_tl: 38018,
    holdings_revenue: 3,
    tl_revenue: 6,
    target_achieved: 97,
  },
  {
    total_holdings: 12237,
    total_tl: 43007,
    unpaid_holdings: 6483,
    unpaid_tl: 6453,
    holdings_revenue: 1,
    tl_revenue: 19,
    target_achieved: 67,
  },
  {
    total_holdings: 29890,
    total_tl: 58744,
    unpaid_holdings: 258,
    unpaid_tl: 23680,
    holdings_revenue: 2,
    tl_revenue: 10,
    target_achieved: 92,
  },
  {
    total_holdings: 15094,
    total_tl: 46566,
    unpaid_holdings: 18494,
    unpaid_tl: 26546,
    holdings_revenue: 4,
    tl_revenue: 14,
    target_achieved: 98,
  },
  {
    total_holdings: 22206,
    total_tl: 32948,
    unpaid_holdings: 11795,
    unpaid_tl: 18210,
    holdings_revenue: 8,
    tl_revenue: 10,
    target_achieved: 63,
  },
  {
    total_holdings: 12321,
    total_tl: 36302,
    unpaid_holdings: 22687,
    unpaid_tl: 30671,
    holdings_revenue: 4,
    tl_revenue: 5,
    target_achieved: 22,
  },
];

// Get All Zone Areas
export function getAllZoneAreas() {
  return axios
    .get(ZONE_AREAS.GET_ZONE_AREAS_API, { timeout: 60000 })
    .then((res) => {
      const zoneAreaGeoJson = {
        type: 'FeatureCollection',
        features: res.data.features
          .filter((f) => f.properties.city_corp === 'DNCC')
          .map((f, i) => {
            return {
              ...f,
              properties: {
                ...f.properties,
                holdings_revenue: dummyData[i].holdings_revenue,
                tl_revenue: dummyData[i].tl_revenue,
                total_holdings: dummyData[i].total_holdings,
                total_tl: dummyData[i].total_tl,
                unpaid_holdings: dummyData[i].unpaid_holdings,
                unpaid_tl: dummyData[i].unpaid_tl,
                target_achieved: `${dummyData[i].target_achieved}%`,
                target_achieved_status: dummyData[i].target_achieved >= 80 ? '80% - 100%' : dummyData[i].target_achieved >= 40 ? '40% - 79%' : '0% - 39%',
              },
            };
          })
          .sort((f1, f2) => {
            const f1_target_achieved = Number(f1.properties.target_achieved.slice(0, f1.properties.target_achieved.length - 1));
            const f2_target_achieved = Number(f2.properties.target_achieved.slice(0, f2.properties.target_achieved.length - 1));

            return f1_target_achieved - f2_target_achieved;
          }),
      };

      return zoneAreaGeoJson;
    })
    .catch((err) => {
      throw err.response && err.response.message ? err.response.message : err;
    });
}

// Generate Zone Dropdown Options
export function generateZoneDropdownOptions(zoneGeoJson) {
  return zoneGeoJson.features
    .map((z) => ({
      value: z.properties.zone_number,
      label: z.properties.zone_number,
      ...z.properties,
    }))
    .sort((a, b) => {
      const labelA = parseInt(a.label.split('-')[1]);
      const labelB = parseInt(b.label.split('-')[1]);

      if (labelA < labelB) {
        return -1;
      } else if (labelA > labelB) {
        return 1;
      } else {
        return 0;
      }
    });
}
