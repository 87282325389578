import axios from 'axios'
import { WARD_POINTS } from '../../App.config'
import * as ActionTypes from './actionTypes'
import { setIsLoading } from './dataActions'

// Set stats data
export function setDashboardData(stats) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_STATS, payload: stats })
  }
}

// Set barchart data
export function setBarchartData(stats) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_BARCHART, payload: stats })
  }
}


export function getBarchartData() {
  return dispatch => {
    console.log("barrrrrrrrr");
    // Set `isValidating
    dispatch( setIsLoading(true))

    let url = WARD_POINTS.GET_DASHBOARD_BARCHART

    axios.get(url)
      .then(res => {
        console.log("resss",res);
        const data = res?.data?.data;
        dispatch(setBarchartData(data))
       
        // Set `isValidating`
        dispatch( setIsLoading(false) )

      })
      .catch(err => {
        console.error(err)
        // Set `isValidating`
        dispatch( setIsLoading(false) )
      })
  }
}