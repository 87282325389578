import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Row, Col, Card } from 'antd'
import ErrorBoundary from '../components/common/ErrorBoundary'
import MapGL from '../components/Map/MapGL'
import RightPanel from '../components/RightPanel/RightPanel'
import { connect } from 'react-redux'
import { getClickedObjectLayerDataId } from '../store/actions/layerActions'
import { WARD_AREAS, WARD_POINTS } from '../App.config'

class MapPage extends React.PureComponent {

  render() {
    const { zoneAreas, wardAreas, wardPoints, selectedZones, selectedWards, selectedPlaceTypes, clicked, layers } = this.props

    return (
      <div style={ containerStyles }>
        <Row gutter={[ 16, 16 ]} style={{ width: '100%', height: '100%' }}>
          {
            // size change of map 
            // based on cliked value
            // if cliked value is ward and point map map gets smaller
            ((clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_AREAS.DATA_ID) ||
            (clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_POINTS.DATA_ID)) ?
              <Col 
                xs={{ span: 24 }} 
                md={{ span: 14 }} style={{ maxHeight: '100%' }}>
                <Card
                  size='small'
                  bordered={ false }
                  bodyStyle={ cardBodyStyles }
                  style={ elevatedCardStyles }
                >
                  <ErrorBoundary>
                    <MapGL
                      zoneAreas={ zoneAreas }
                      wardAreas={ wardAreas }
                      wardPoints={ wardPoints }
                      selectedZones={ selectedZones }
                      selectedWards={ selectedWards }
                      selectedPlaceTypes={ selectedPlaceTypes }
                    />
                  </ErrorBoundary>
                </Card>
              </Col>
              :
              <Col 
                xs={{ span: 24 }} 
                md={{ span: 24 }} style={{ maxHeight: '100%' }}>
                <Card
                  size='small'
                  bordered={ false }
                  bodyStyle={ cardBodyStyles }
                  style={ elevatedCardStyles }
                >
                  <ErrorBoundary>
                    <MapGL
                      zoneAreas={ zoneAreas }
                      wardAreas={ wardAreas }
                      wardPoints={ wardPoints }
                      selectedZones={ selectedZones }
                      selectedWards={ selectedWards }
                      selectedPlaceTypes={ selectedPlaceTypes }
                    />
                  </ErrorBoundary>
                </Card>
              </Col>

          }         
         
         {
             ((clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_AREAS.DATA_ID) ||
             (clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_POINTS.DATA_ID)) ?
             <Col xs={{ span: 24 }} md={{ span: 10 }} style={{ maxHeight: '100%' }}>
              <Card
                size='small'
                bordered={ false }
                bodyStyle={ cardBodyStyles }
                style={ elevatedCardStyles }
              >
                <RightPanel />
              </Card>
            </Col>
             :
             ""
         }

        </Row>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%'
}

const elevatedCardStyles = {
  width: '100%',
  height: '100%',
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

const cardBodyStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  alignItems: 'stretch'
}

// Prop Types
MapPage.propTypes = {
  zoneAreas: PropTypes.object,
  wardAreas: PropTypes.object,
  wardPoints: PropTypes.object,
  selectedZones: PropTypes.array,
  selectedWards: PropTypes.array,
  selectedPlaceTypes: PropTypes.array,
  clicked: PropTypes.object,
  layers: PropTypes.array
}

MapPage.defaultProps = {
  zoneAreas: null,
  wardAreas: null,
  wardPoints: null,
  selectedZones: [],
  selectedWards: [],
  selectedPlaceTypes: [],
  clicked: null,
  layers: []
}

const mapStateToProps = state => ({
  clicked: state?.keplerGl?.map?.visState?.clicked ?? null,
  layers: state?.keplerGl?.map?.visState?.layers ?? []
})

export default connect(mapStateToProps)(MapPage)