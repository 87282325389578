import axios from 'axios';
import { WARD_AREAS, WARD_POINTS } from '../App.config';
import { setDashboardData } from '../store/actions/dashboardActions';
import { setIsLoading } from '../store/actions/dataActions';

// Get All Ward Areas
export function getAllWardAreas() {
  return axios
    .get(WARD_AREAS.GET_WARD_AREAS_API, { timeout: 60000 })
    .then((res) => {
      const wardAreaGeoJson = {
        type: 'FeatureCollection',
        features: res.data.features.filter((f) => f.properties.zone_number.includes('DNCC')),
      };

      return wardAreaGeoJson;
    })
    .catch((err) => {
      throw err.response && err.response.message ? err.response.message : err;
    });
}

// Generate Ward Dropdown Options
export function generateWardDropdownOptions(wardGeoJson) {
  // From Ward Area GeoJSON List
  const { features } = wardGeoJson;
  let wardOptions = [{ value: 'All', label: 'All' }];

  let index = -1;
  for (let i = 0; i < features.length; i++) {
    const { properties } = features[i];
    index = wardOptions.findIndex((el) => el.label === properties.zone_number);

    // If element not found
    if (index === -1) {
      wardOptions.push({
        label: properties.zone_number,
        options: [
          {
            value: properties.ward_number,
            label: properties.ward_number,
            ...properties,
          },
        ],
      });
    } else {
      wardOptions[index].options.push({
        
        value: properties.ward_number,
        label: properties.ward_number,
        ...properties,
      });
    }
  }

  // Sort Menu Groups by city_corp and zone_number
  wardOptions.sort((a, b) => {
    let labelA = a.label.toLowerCase().split(' ')[0];
    let labelB = b.label.toLowerCase().split(' ')[0];

    // Sort by city_corp
    if (labelA < labelB) {
      return -1;
    } else if (labelA > labelB) {
      return 1;
    } else {
      // Sort by zone_number
      labelA = parseInt(a.label.split('-')[1]);
      labelB = parseInt(b.label.split('-')[1]);

      if (labelA < labelB) {
        return -1;
      } else if (labelA > labelB) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  // Sort Each Options by value
  wardOptions.forEach((group) => {
    if (group.label !== 'All') {
      // Sort Options in Group
      group.options.sort((a, b) => {
        const labelA = parseInt(a.label.split(' ')[0].split('-')[1]);
        const labelB = parseInt(b.label.split(' ')[0].split('-')[1]);

        if (labelA < labelB) {
          return -1;
        } else if (labelA > labelB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  });

  // Filter Out only DNCC Options
  wardOptions = wardOptions.filter((w) => w.label === 'All' || w.label.includes('DNCC'));

  return wardOptions;
}

// Get Ward Points Data and Add to Map
export function getWardPointsData(wardId, config, wards) {
  // var wardNumber;

  // if (wards.length === 1) {
  //   if (wards[0].startsWith('Ward-17')) {
  //     wardNumber = 17;
  //   } else if (wards[0].startsWith('Ward-1')) {
  //     wardNumber = 1;
  //   }
  // }

  // if (wardNumber === 1 || wardNumber === 17) {
    //  static ucodes from csv file

    let url = `${WARD_POINTS.GET_WARD1_17_POINTS_API}?ward=${wardId}`;

    return axios
      .get(url)
      .then((res) => {
        // Create GeoJson
        const wardPoints = {
          type: 'FeatureCollection',
          features: [],
        };

        //  storing holding_info
        const holdingInfo = {
          total_holdings: res?.data?.data?.total_holdings ?? null,
          paid_holdings: res?.data?.data?.paid_holdings ?? null,
          unpaid_holdings: res?.data?.data?.unpaid_holdings ?? null
        }

        if (res.data.data) {
          console.log('ward  1 17', res.data.data);
          const { places } = res.data.data;
          if (places) {
            wardPoints.features = places.map((p) => ({
              type: 'Feature',
              properties: {
                ...Object.fromEntries(Object.entries(p).filter((e) => e[0] !== 'longitude' && e[0] !== 'latitude')),
              },
              geometry: {
                type: 'Point',
                coordinates: [p.longitude, p.latitude],
              },
            }));
          }
        }
        console.log('ward geojson 1 17', wardPoints);
        return [wardPoints, holdingInfo];
      })
      .catch((err) => {
        throw err?.response && err?.response?.message ? err?.response?.message : err;
      });
  // } else {
  //   return axios
  //     .get(WARD_POINTS.GET_WARD_POINTS_API + wardId, config)
  //     .then((res) => {
  //       console.log('common res', res);
  //       // Create GeoJson
  //       const wardPoints = {
  //         type: 'FeatureCollection',
  //         features: [],
  //       };

  //       //  storing holding_info
  //       const holdingInfo = {
  //         total_holdings: res?.data?.data?.total_holdings ?? null,
  //         paid_holdings: res?.data?.data?.paid_holdings ?? null,
  //         unpaid_holdings: res?.data?.data?.unpaid_holdings ?? null
  //       }

  //       if (res.data.data) {
  //         const { places } = res.data.data;
  //         if (places) {
  //           wardPoints.features = places.map((p) => ({
  //             type: 'Feature',
  //             properties: {
  //               ...Object.fromEntries(Object.entries(p).filter((e) => e[0] !== 'longitude' && e[0] !== 'latitude')),
  //             },
  //             geometry: {
  //               type: 'Point',
  //               coordinates: [p.longitude, p.latitude],
  //             },
  //           }));
  //         }
  //       }
  //       return [wardPoints, holdingInfo];
  //     })
  //     .catch((err) => {
  //       throw err.response && err.response.message ? err.response.message : err;
  //     });
  // }
}

// export function getBarchartData() {
//   return dispatch => {
//     console.log("barrrrrrrrr");
//     // Set `isValidating
//     dispatch( setIsLoading(true))

//     let url = WARD_POINTS.GET_DASHBOARD_BARCHART

//     axios.get(url)
//       .then(res => {
//         console.log("resss",res);
//         const data = res?.data?.data;
//         dispatch(setBarchartData(data))
       
//         // Set `isValidating`
//         dispatch( setIsLoading(false) )

//       })
//       .catch(err => {
//         console.error(err)
//         // Set `isValidating`
//         dispatch( setIsLoading(false) )
//       })
//   }
// }

// Get Ward Points Data and Add to Map
export function getDashboardData( wardId, wards) {
  return dispatch => {
    // var wardNumber;

    // if (wards?.length === 1) {
    //   if (wards[0].startsWith('Ward-17')) {
    //     wardNumber = 17;
    //   } else if (wards[0].startsWith('Ward-1')) {
    //     wardNumber = 1;
    //   }
    // }

    console.log("pitttttttttts");
    var url = `${WARD_POINTS.GET_DASHBOARD_DATA_API}`;

    // if (wardNumber === 1 || wardNumber === 17) {
      //  static ucodes from csv file



    if(wardId){
      url = `${WARD_POINTS.GET_DASHBOARD_DATA_API}?ward=${wardId}`;
    }

     axios.get(url)
      .then((res) => {
        const stats = res?.data?.data
        console.log("stats from api", stats);

        dispatch(setDashboardData(stats)); // data[0] ===> wardPoints  geojson structure
        dispatch(setIsLoading(false));
        // return stats;
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        throw err?.response && err?.response?.message ? err?.response?.message : err;
      });
    // }
  }
}

// Transform Ward Points to activate beeping effect based on key-value
export function transformWardPointsForBeepingEffect(wardPoints, key, value) {
  const _wardPoints = wardPoints.features.map((f) => ({
    ...f.properties,
    longitude: f.geometry.coordinates[0],
    latitude: f.geometry.coordinates[1],
    beep: f.properties[key] === value ? true : false,
  }));

  return _wardPoints;
}

// Get Holding Details by uCode
export function getHoldingDetails(uCode) {
  return axios
    .get(WARD_POINTS.GET_HOLDING_DETAILS, { params: { uCode } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response && err.response.message ? err.response.message : err;
    });
}
