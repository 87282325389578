import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  wardDropdownOptions: [],
  selectedWards: [],
  wardPoints: null,
  holdingInfo: null
}

const wardReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_WARD_DROPDOWN_OPTIONS:
      return {
        ...state,
        wardDropdownOptions: action.payload
      }
    
    case ActionTypes.SET_SELECTED_WARDS:
      return {
        ...state,
        selectedWards: action.payload
      }
    
    case ActionTypes.SET_WARD_POINTS:
      return {
        ...state,
        wardPoints: action.payload 
      }

    case ActionTypes.SET_HOLDING_INFO:
      return {
        ...state,
        holdingInfo: action.payload 
      }

    default:
      return state
  }
}

export default wardReducer