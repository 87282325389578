import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { AutoComplete, Input } from 'antd'

// Import Actions & Methods
import { updateMap } from 'kepler.gl/actions'

// Constants
const { Search } = Input

class SearchMap extends React.PureComponent {
  state = {
    isAutocompleteDataLoading: false,
    searchQuery: '',
    options: [],
    selectedOption: null
  }

  // On Change
  _onChange = searchQuery => {
    this.setState({ isAutocompleteDataLoading: true, searchQuery, options: [], selectedOption: null })

    // Get Autocomplete Data
    const options = this._getAutocompleteOptions(searchQuery.trim())

    this.setState({ isAutocompleteDataLoading: false, options })
  }

  // On Select
  _onSelect = (selectedValue, selectedOption) => {
    const { dispatch } = this.props

    this.setState({ selectedOption })

    // Zoom To Place
    if(selectedOption?.data?.longitude && selectedOption?.data?.latitude) {
      dispatch(
        updateMap({ longitude: selectedOption.data.longitude, latitude: selectedOption.data.latitude, zoom: 20 })
      )
    }
  }

  // Get Autocomplete Options
  _getAutocompleteOptions = searchQuery => {
    const { wardPoints } = this.props

    if(!searchQuery.trim() || !wardPoints?.features?.length) {
      return []
    }

    return wardPoints.features.filter(f => {
      let searchRow = (f?.properties?.uCode ?? '') + (f?.properties?.business_name ?? '') + (f?.properties?.place_name ?? '') + (f?.properties?.Address ?? '')

      if(searchRow.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())) {
        return true
      }

      return false

    }).map((d, i) => ({
      key: i,
      value: d?.properties?.business_name ?
        d?.properties?.business_name :
        d?.properties?.place_name ?
        d?.properties?.place_name :
        d?.properties?.Address ?
        d?.properties?.Address :
        d?.properties?.uCode ?
        d?.properties?.uCode :
        '',
      label: d?.properties?.business_name ?
        d?.properties?.business_name :
        d?.properties?.place_name ?
        d?.properties?.place_name :
        d?.properties?.Address ?
        d?.properties?.Address :
        d?.properties?.uCode ?
        d?.properties?.uCode :
        '',
      data: {
        ...d?.properties ?? {},
        longitude: d?.geometry?.coordinates?.length > 0 ? d?.geometry?.coordinates[0] : null,
        latitude: d?.geometry?.coordinates?.length > 1 ? d?.geometry?.coordinates[1] : null,
      }
    }))
  }

  render() {
    const { isAutocompleteDataLoading, searchQuery, options } = this.state

    return (
      <div style={ containerStyles }>
        <AutoComplete
          options={ options }
          value={ searchQuery }
          onChange={ this._onChange }
          onSelect={ this._onSelect }
          style={{ width: '100%' }}
          >
          <Search
            placeholder='Search places...'
            size='small'
            loading={ isAutocompleteDataLoading }
            allowClear={ true }
            enterButton={ true }
          />
        </AutoComplete>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {

}

// Prop Types
SearchMap.propTypes = {
  wardPoints: PropTypes.object
}

SearchMap.defaultProps = {
  wardPoints: null
}

const mapStateToProps = state => ({
  wardPoints: state?.ward?.wardPoints ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(SearchMap)