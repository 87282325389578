import React from 'react'

// Import Components
import { Row, Col, Card, Statistic, Progress } from 'antd'
import TargetChart from './TargetChart'
import RevenueChart from './RevenueChart'
import { connect } from 'react-redux'
import { getDashboardData } from '../../utils/wardUtils'
import { getBarchartData } from '../../store/actions/dashboardActions'
import CountUp from 'react-countup';
import dhaka from "./../../assets/dhaka.jpg"

const formatter = (value) => <CountUp end={value} separator="," />;

class Dashboard extends React.PureComponent {

  componentDidMount(){

    const { dispatch } = this.props
    // getDashboardData(0);
    dispatch(getDashboardData());

    dispatch(getBarchartData());
    // getDashboardData()
  }



  render() {
    const {stats} = this.props
    return (
      <div style={ containerStyles }>
        {
          console.log("stats",stats)
        }
        {/* <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Target (2021 - 2022)'
                value={ 500 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#ffa500'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Achieved (Upto Today)'
                value={ 10 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#2ddbac'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Achieved (2021 - 2022)'
                value={ 220 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#2ddbac'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Remaining (2021 - 2022)'
                value={ 280 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#c70039'
                }}
              />
            </Card>
          </Col>
        </Row> */}

        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            // marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <Card hoverable={ true } style={{ ...cardHoveredStyles, width: '100%' }}>
              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card 
                  hoverable={ true }                     
                  style={{
                        // color: '#2ddbac'
                        backgroundImage:`url("${dhaka}")`,
                        backgroundSize: 'cover',

                        backgroundPosition: 'center',
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        opacity: '0.9'
                      }}>
                    <Statistic
                      className='holding'   
                      title='Total Holdings'
                      value={ stats?.holding?.total_holding }
                      valueStyle={{
                        // color: '#2ddbac'
                        // backgroundImage:`url("${dhaka}")` 
                        color: "white",
                        fontWeight: "bolder",
                        fontSize: '40px !important'

                      }}
                      style={{
                        color: "white",
                        fontSize: '40px !important'
                      }}
                      formatter={formatter}
                    />
                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Total Trade Licenses'
                      value={ stats?.license?.total_trade_license }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                      formatter={formatter}
                    />
                  </Card>
                </Col>
              </Row>

              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <div  style={{display:"flex", flexDirection: "row", justifyContent: 'space-between'}}>
                      <Statistic
                        title='Achieved From Holdings'
                        value={ stats?.holding?.achive_from_holding ? stats?.holding?.achive_from_holding + "%" :  0 + "%" }
                        valueStyle={{
                          color: 'green',
                          fontWeight:'bold'
                        }}
                      />
                      <Progress        title='Achieved From Holdings' type="circle" percent={stats?.holding?.achive_from_holding ?? 0} />
                    </div>

                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                  <div style={{display:"flex", flexDirection: "row", justifyContent: 'space-between'}}>
                    <Statistic
                      title='Achieved From Trade Licenses'
                      value={ stats?.license?.achive_from_trade_license ? + stats?.license?.achive_from_trade_license + "%" : 0 + "%" }
                      valueStyle={{
                        color: 'green',
                        fontWeight:'bold'
                      }}
                    />
                    <Progress title='Achieved From Trade Licenses' type="circle" percent={stats?.license?.achive_from_trade_license  ?? 0} />
                  </div>

                  </Card>
                </Col>
              </Row>

              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                  <div style={{display:"flex", flexDirection: "row", justifyContent: 'space-between'}}>
                    <Statistic
                      title="Need to be Achieved From Holdings"
                      value={ stats?.holding?.not_achive_from_holding ? stats?.holding?.not_achive_from_holding + '%' : 0 + "%" }
                      valueStyle={{
                        color: 'red',
                        fontWeight: 'bold'
                      }}
                    />
                    <Progress title="Didn't Achieved From Holdings" type="circle" percent={stats?.holding?.not_achive_from_holding  ?? 0} status="exception" />
                  </div>
                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                  <div style={{display:"flex", flexDirection: "row", justifyContent: 'space-between'}}>
                    <Statistic
                      title="Need to be Achieved From Trade Licenses"
                      value={ stats?.trade_license?.not_achive_from_trade_license ? stats?.trade_license?.not_achive_from_trade_license + "%" : 0 + "%" }
                      valueStyle={{
                        color: 'red',
                        fontWeight: 'bold'
                      }}
                    />
                    <Progress title="Didn't Achieved From Trade Licenses" type="circle" percent={stats?.trade_license?.not_achive_from_trade_license ?? 0} status="exception" />
                  </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <Card style={ cardHoveredStyles }>
              <TargetChart />
            </Card>
          </Col>

          <Col xs={ 24 }>
            <Card style={ cardHoveredStyles }>
              <RevenueChart />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: '32px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const cardHoveredStyles = {
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

const mapStateToProps = state => ({
  stats: state.dashboard?.stats
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)