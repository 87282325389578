// Locale Action Types
export const SET_LOCALE = 'SET_LOCALE'

// Nav Action Types
export const SET_SELECTED_TAB_KEY = 'SET_SELECTED_TAB_KEY'

// Zone Reducer Action Types
export const SET_SELECTED_ZONES = 'SET_SELECTED_ZONES'

// Ward Reducer Action Types
export const SET_WARD_DROPDOWN_OPTIONS = 'SET_WARD_DROPDOWN_OPTIONS'
export const SET_SELECTED_WARDS = 'SET_SELECTED_WARDS'
export const SET_WARD_POINTS = 'SET_WARD_POINTS'
export const SET_HOLDING_INFO = 'SET_HOLDING_INFO'

// Data Reducer Action Types
export const SET_IS_LOADING = 'SET_IS_LOADING'

// Auth Action Types
export const AuthActionTypes = {
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  SET_IS_VALIDATING: 'SET_IS_VALIDATING',
  SET_EMAIL: 'SET_EMAIL',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_AUTH_ERROR: 'SET_AUTH_ERROR'
}

// Dashboard Action Types
export const SET_STATS = 'SET_STATS'
export const SET_BARCHART = 'SET_BARCHART'