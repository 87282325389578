import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Space, Divider, Button } from 'antd'
import ZoneSelectMenu from './ZoneSelectMenu'
import WardSelectMenu from './WardSelectMenu'
import PlaceTypeSelectMenu from './PlaceTypeSelectMenu'

class HomeTabPane extends React.PureComponent {
    render() {
        const { zoneDropdownOptions, wardDropdownOptions, placeTypeOptions, selectedZones, selectedWards, selectedPlaceTypes, onZoneChange, onWardChange, onPlaceTypeChange, wardPoints, isLoading } = this.props

        return (
            <div style={ containerStyles }>
                <Space direction='vertical' size='large' style={{ width: '100%' }}>
                    <ZoneSelectMenu
                        zoneDropdownOptions={ zoneDropdownOptions }
                        selectedZones={ selectedZones }
                        onChange={ onZoneChange }
                        disabled={ isLoading }
                    />

                    <WardSelectMenu
                        wardDropdownOptions={ wardDropdownOptions }
                        selectedWards={ selectedWards }
                        onChange={ onWardChange }
                        disabled={ isLoading }
                    />

                    <PlaceTypeSelectMenu
                        placeTypeOptions={ placeTypeOptions }
                        selectedPlaceTypes={ selectedPlaceTypes }
                        onPlaceTypeChange={ onPlaceTypeChange }
                        disabled={ isLoading || !wardPoints }
                    />

                    <div>
                        <Divider
                            plain={ true }
                            style={{
                                margin: 0,
                                marginBottom: '8px',
                                color: '#a0a0a0'
                            }}
                        >
                            { 'Export Data' }
                        </Divider>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                gap: '8px'
                            }}
                        >
                            <Button
                                size='small'
                                type='dashed'
                            >
                                { 'Excel' }
                            </Button>

                            <Button
                                size='small'
                                type='primary'
                            >
                                { 'PDF' }
                            </Button>
                        </div>
                    </div>
                </Space>
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    padding: '0px 24px 8px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

// Prop Types
HomeTabPane.propTypes = {
    zoneDropdownOptions: PropTypes.array,
    wardDropdownOptions: PropTypes.array,
    placeTypeOptions: PropTypes.array,
    selectedZones: PropTypes.array,
    selectedWards: PropTypes.array,
    selectedPlaceTypes: PropTypes.array,
    onZoneChange: PropTypes.func,
    onWardChange: PropTypes.func,
    onPlaceTypeChange: PropTypes.func,
    wardPoints: PropTypes.object,
    isLoading: PropTypes.bool
}

HomeTabPane.defaultProps = {
    zoneDropdownOptions: [],
    wardDropdownOptions: [],
    placeTypeOptions: [],
    selectedZones: [],
    selectedWards: [],
    selectedPlaceTypes: [],
    onZoneChange: () => null,
    onWardChange: () => null,
    onPlaceTypeChange: () => null,
    wardPoints: null,
    isLoading: false
}

export default HomeTabPane