import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// Import Components
import MapStyleControl from '../components/Map/MapStyleControl'
import LocaleControl from '../components/Map/LocaleControl'
import FullscreenControl from '../components/Map/FullscreenControl'

// Import Redux Store
import store from '../store/store'

// Import Assets
import barikoiLogoWhite from '../assets/bkoi_white.svg'

// Render Barikoi Attributions
export function renderBarikoiAttributions() {
  const attrIntervalId = setInterval(() => {
    const attributionLogo = document.querySelector('.mapbox-attribution-container .attrition-logo .mapboxgl-ctrl-logo')
    const attributionLink = document.querySelector('.mapbox-attribution-container > div:last-child')

    // Check if Elements Found
    if(attributionLogo && attributionLink) {
      // New Attribution Logo
      const newAttributionLogo = document.createElement('a')
      newAttributionLogo.setAttribute('class', 'barikoigl-ctrl-logo')
      newAttributionLogo.setAttribute('target', '_blank')
      newAttributionLogo.setAttribute('rel', 'noopener noreferrer')
      newAttributionLogo.setAttribute('aria-label', 'Barikoi Logo')
      newAttributionLogo.style.marginLeft = '4px'

      // Add Barikoi Logo SVG
      const svg = document.createElement('img')
      svg.setAttribute('src', barikoiLogoWhite)
      svg.setAttribute('width', '40px')
      svg.setAttribute('height', '14px')
      svg.style.marginTop = '-2px'
      newAttributionLogo.appendChild(svg)


      attributionLogo.parentNode.replaceChild(newAttributionLogo, attributionLogo)

      // Remove `improve this map` and `kepler.gl`
      attributionLink.children[0].remove()
      attributionLink.children[2].remove()

      // Remove `|` on the last attribution
      attributionLink.children[1].innerHTML = '© OpenStreetMap'

      // Clear Interval
      clearInterval(attrIntervalId)
    }
  })
}

// Render Map Style Action Panel Into Map Control DOM
export function renderCustomActionPanelsIntoDom() {
  const mapStyleIntervalId = setInterval(() => {
    let mapControlContainer = document.querySelector('.map-control')
    const lastChild = document.querySelector('.map-control > div:last-child')

    if(mapControlContainer && lastChild) {
      /////////////////////////
      // Map Style Container //
      /////////////////////////
      // Create Map Style Container
      const mapStyleContainer = document.createElement('div')
      mapStyleContainer.className = 'map-control-button map-style-control'
      mapStyleContainer.style.width = '100%'
      mapStyleContainer.style.display = 'flex'
      mapStyleContainer.style.flexDirection = 'row'
      mapStyleContainer.style.justifyContent = 'flex-end'
      mapStyleContainer.style.alignItems = 'center'

      // Append To Map Control Container
      mapControlContainer.appendChild(mapStyleContainer)

      // Render Map Style Control Component
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={ store }>
            <MapStyleControl />
          </Provider>
        </React.StrictMode>,
        mapStyleContainer
      )

      /////////////////////////
      // Locale Container //
      /////////////////////////
      // Create Locale Container
      const localeContainer = document.createElement('div')
      localeContainer.className = 'map-control-button locale-control'
      localeContainer.style.width = '100%'
      localeContainer.style.display = 'flex'
      localeContainer.style.flexDirection = 'row'
      localeContainer.style.justifyContent = 'flex-end'
      localeContainer.style.alignItems = 'center'

      // Append To Map Control Container
      mapControlContainer.appendChild(localeContainer)

      // Render Locale Control Component
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={ store }>
            <LocaleControl />
          </Provider>
        </React.StrictMode>,
        localeContainer
      )

      //////////////////////////
      // Fullscreen Container //
      //////////////////////////
      // Create Fullscreen Container
      const fullscreenContainer = document.createElement('div')
      fullscreenContainer.className = 'map-control-button fullscreen-control'
      fullscreenContainer.style.width = '100%'
      fullscreenContainer.style.display = 'flex'
      fullscreenContainer.style.flexDirection = 'row'
      fullscreenContainer.style.justifyContent = 'flex-end'
      fullscreenContainer.style.alignItems = 'center'

      // Append To Map Control Container
      mapControlContainer.appendChild(fullscreenContainer)

      // Render Fullscreen Control Component
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={ store }>
            <FullscreenControl />
          </Provider>
        </React.StrictMode>,
        fullscreenContainer
      )

      // Hide Split Map
      const splitMapControl = document.querySelector('.split-map')
      if(splitMapControl) {
        splitMapControl.style.display = 'none'
      }

      // Hide Map Draw Control
      mapControlContainer.children[ mapControlContainer.children.length - 5 ].style.display = 'none'

      // Hide Map Locale Control
      mapControlContainer.children[ mapControlContainer.children.length - 4 ].style.display = 'none'

      // Clear Interval
      clearInterval(mapStyleIntervalId)
    }
  })
}