import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

// Import Components
import { Spin } from 'antd'
import ProtectedRoute from './components/common/ProtectedRoute'
import Login from './pages/Login'
import UrbanEngine from './pages/UrbanEngine'

// Import Styles
import 'antd/dist/antd.css'
import './App.css'

// Import Actions & Methods
import { validateUser } from './store/actions/authActions'

class App extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    // Validate User
    const token = localStorage.getItem('token')
    dispatch( validateUser(token) )
  }

  render() {
    const { isAuthenticated, isValidating } = this.props

    return (
      <div style={ containerStyles }>
        { isValidating ?
          <Spin />
          :
          <BrowserRouter>
            <Routes>
              <Route
                exact={ true }
                path='/login'
                element={
                  isAuthenticated ?
                    <Navigate to='/' />
                    :
                    <Login />
                }
              />

              <Route
                exact={ true }
                path='/'
                element={
                  <ProtectedRoute
                    isAuthenticated={ isAuthenticated }
                    element={
                      <UrbanEngine />
                    }
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        }
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

// Prop Types
App.propTypes = {
  isAuthenticated: PropTypes.bool,
  isValidating: PropTypes.bool,
  dispatch: PropTypes.func
}

App.defaultProps = {
  isAuthenticated: false,
  isValidating: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated ?? false,
  isValidating: state?.auth?.isValidating ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(App)